import { Configuration } from 'src/app/shared/models/sys/configuration';

export const environment: Configuration = {
  baseUrl: 'https://mavic-frontend.votre-projet.com',
  baseRelPath: './browser',
  apiProtocol: 'https://',
  apiHost: 'mavicshop.votre-projet.com',
  gtmId: 'GTM-5GSMQQQL',
  googleMapsApiKey: 'AIzaSyBYZoy1lJ83PKWGEzN-ECCiW6u97J12YWE',
  tracking: {
    views: true,
    events: true,
  },
  doFinderOverrides: {
    baseUrl: 'https://eu1-search.doofinder.com',
    hashId: '44dc9a97b5220b84a5388ab875247e68',
    token: 'cc206ed4ac7c40c02236f8299fc9c19e8ed2067a',
  },
};
